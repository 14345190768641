<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="'Busqueda de ' + title"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:item.foto="{ item }">
          <div class="logo-marcas">
            <v-img :src="item.foto" alt=""> </v-img>
          </div>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="30%" persistent>
              <template v-if="visible" v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="$cv('principal')"
                  dark
                  class="mx-2"
                  fab
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon blue>mdi-plus</v-icon></v-btn
                >
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-toolbar dark :color="$cv('principal')">
                    <v-btn icon dark @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn dark text @click="saveModelo()" :disabled="!valid"
                        >Guardar</v-btn
                      >
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="12" class="pa-0 pl-2 pr-2">
                          <v-autocomplete
                            :items="marcas"
                            item-value="id"
                            item-text="nombre"
                            label="Marca"
                            v-model="editedItem.marca_id"
                            :rules="[$rulesRequerido]"
                            filled
                            dense
                          >
                            <template v-slot:item="{ item }">
                              <v-row class="row-marcas-select">
                                <span class="label-marcas-select">{{
                                  item.nombre
                                }}</span>
                                <v-img
                                  class="logo-marcas-select"
                                  :src="item.logo_url"
                                  loading="lazy"
                                />
                              </v-row>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field
                            v-model="editedItem.nombre"
                            :label="title"
                            :rules="[$rulesRequerido]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-text-field
                            v-model="editedItem.foto"
                            label="Url Foto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="visible"
            small
            class="mr-2"
            :color="$cv('btnEditar')"
            title="Editar"
            @click="$editItem(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="visible"
            small
            :color="$cv('btnEliminar')"
            title="Eliminar"
            @click="deleteModelo(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Modelo";
}

export default {
  data: (vm) => ({
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    marcas: [],
    route: "marcas_modelos",
    url: "modelos",
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    search: "",
    users: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Marca", filterable: true, value: "marca" },
      { text: title(), filterable: true, value: "nombre" },
      { text: "Logo", filterable: true, value: "foto" },
      { text: "Creado", value: "created_at" },
      { text: "Actualizado", value: "updated_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      marca_id: "",
      marca: "",
      modelo_id: "",
      nombre: "",
      foto: "",
    },
    defaultItem: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  created() {
    this.$initialize();
  },

  methods: {
    async deleteModelo(item) {
      let vm = this;
      let id = item.id;
      let nombre = item.marca + " " + item.nombre;
      let titulo = vm.title;
      if (titulo.substring(titulo.length - 1) == "s")
        titulo = titulo.substring(0, titulo.length - 1);

      vm.$confirm({
        message: "¿Desea ELIMINAR el " + titulo + " " + nombre + "?",
        button: {
          no: "Cancelar",
          yes: "Eliminar",
        },

        callback: async function (confirm) {
          if (confirm) {
            vm.$alertGetInfo("Eliminando Registro");
            await vm.$axiosApi
              .delete(vm.route, id)
              .then((r) => {
                vm.$axiosApi
                  .delete(vm.url, item.modelo_id)
                  .then((res) => {
                    vm.$respuesta(vm, res, 0);
                    Swal.close();
                  })
                  .catch((ex) => {
                    vm.$respuesta(vm, ex, 0, true);
                  });
              })
              .catch((e) => {
                vm.$respuesta(vm, e, 0, true);
              });
            // Swal.close();
          }
        },
      });
    },
    async saveModelo() {
      const modeloPromise = new Promise((resolve, reject) => {
        let vm = this;
        console.log(vm.editedIndex);
        if (vm.$refs.form.validate()) {
          if (vm.editedIndex > -1) {
            vm.$axiosApi
              .put(vm.url, vm.editedItem.modelo_id, vm.editedItem)
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                reject("error: " + e);
              });
          } else {
            vm.$axiosApi
              .post(vm.url, vm.editedItem)
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                reject("error: " + e);
              });
          }
        }
      });
      modeloPromise
        .then((value) => {
          if (value.status == 200) {
            console.log(value.data);
            this.editedItem.modelo_id = value.data.data.id;
            //this.editedItem.modelo_id = value.
            this.$save();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMarcas() {
      this.$axiosApi
        .get("marcas")
        .then((response) => {
          this.marcas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener marcas. Error: " + response.message;
          this.color = "error";
        });
    },
  },

  mounted() {
    console.log("Componente " + this.title + " creado");
    this.getMarcas();
  },
};
</script>
